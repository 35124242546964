
import { AiFillInstagram, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';
import { GrPinterest } from 'react-icons/gr';
import { FaFacebookSquare } from "react-icons/fa";

interface ISocialMedia {
    className?: string;
}

export default function SocialMedia({ className } : ISocialMedia) {
    return (
        <div className={`social-icons flex gap-4 items-center mb-4 ${className}`}>
            <a href="https://www.facebook.com/cannoli.fi" target="_blank" rel="noreferrer">
            <FaFacebookSquare size={30} />
            </a>
            <a href="http://instagram.com/cannoli.fi" target="_blank" rel="noreferrer">
            <AiFillInstagram size={34} />
            </a>
            <a href="https://fi.pinterest.com/cannolifi/" target="_blank" rel="noreferrer">
            <GrPinterest size={26} />
            </a>
        </div>
    )
}