import { Link, NavLink, useLocation } from "@remix-run/react";
import { useState } from "react";
import SocialMedia from "../SocialMedia";

interface IHeader {
    logo: {
        url: string;
    };
    navigationLeft: any[];
    navigationRight: any[];
    locale: string;
}

export default function Header({logo, navigationLeft, navigationRight, locale} : IHeader) {
    const [isOpen, setIsOpen] = useState(false);
	const handleClick = (e : any) => {
		e.preventDefault();
		setIsOpen((previous : boolean) => !previous);
	};

    return (
        <header className="bg-whitepure">
            <div className="container">
                <div className="sm:flex sm:justify-center sm:items-center sm:py-2">
                    <div className="flex items-center justify-between py-2 sm:p-0">
                        <div className="sm:hidden z-30">
                            <NavLink to="/">
                                {logo && <img src={logo.url} aria-label="logo" width="100px" />}
                            </NavLink>
                        </div>
                        <div className="flex justify-between items-center">
                            <SocialMedia className="sm:hidden justify-center text-black mt-3 mr-8" />
                            <button
                                type="button"
                                className="rounded-none text-primary focus:text-gray-800 hover:text-primary sm:hidden dark:text-white dark:focus:text-primary -mt-1 p-0 z-30"
                                onClick={handleClick}
                            >
                                <svg
                                    className="fill-current h-6 w-6"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    {isOpen ? (
                                        <path d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                                    ) : (
                                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                                    )}
                                    <title>Menu</title>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="items-center flex gap-9 sm:w-full">
                        <nav className={`${isOpen ? 'flex-col nav-open' : 'max-sm:hidden'} sm:w-full flex sm:justify-center sm:items-center sm:py-2 max-sm:bg-whitepure max-sm:inset-x-0 max-sm:top-[7rem] max-sm:fixed max-sm:justify-center max-sm:p-4 max-sm:z-20 max-sm:text-center `}
                             onClick={() => setIsOpen(false)}>
                            <ul className="sm:flex sm:flex-1 sm:items-center sm:justify-end">
                                {/* <li className="sm:mx-2">
                                    <NavLink to='/'
                                        className="block mt-2 py-1 underline-anim underline-animate text-black font-medium sm:text-sm">
                                        Koti
                                    </NavLink>
                                </li> */}
                                {navigationLeft && navigationLeft.length && navigationLeft.map((item : any, i : number) =>
                                <li className="sm:mx-2" key={i}>
                                    <NavLink to={item.title.toLowerCase()}
                                        className="block mt-2 py-1 underline-anim underline-animate text-black font-medium sm:text-sm">
                                        {item.menuTitle || item.title}
                                    </NavLink>
                                </li>)}
                            </ul>
                            <div className="mx-4 max-sm:hidden">
                                <NavLink to="/">
                                    {logo && <img src={logo.url} aria-label="logo" width="100px" />}
                                </NavLink>
                            </div>
                            <ul className="sm:flex sm:flex-1 sm:items-center sm:justify-start">
                                {navigationRight && navigationRight.length && navigationRight.map((item : any, i : number) =>
                                <li className="sm:mx-2" key={i}>
                                    <NavLink to={item.title.toLowerCase()}
                                        className="block mt-2 py-1 underline-anim underline-animate text-black font-medium sm:text-sm">
                                        {item.menuTitle || item.title}
                                    </NavLink>
                                </li>)}
                            </ul>
                        </nav>

                        {/* <ul className="navbar-nav ms-auto flex gap-2">
                            <li className="nav-item">
                                <Link to="/en/" reloadDocument={true} className="nav-link">
                                EN
                                </Link>
                            </li> | 
                            <li className="nav-item">
                                <Link to="/fi" reloadDocument={true} className="nav-link">
                                FI
                                </Link>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </header>
        
    )
}